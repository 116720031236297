import { alpha, createTheme, ThemeOptions } from '@mui/material'
import React from 'react'
import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  label: React.CSSProperties
}

const themeWithVars = createTheme({
  palette: {
    primary: {
      main: '#FFBE00',
    },

    common: {
      black: '#0D0A00',
      white: '#FFFFFF',
      green: '#4BBF53',
      red: '#D62E2E',
      golden: '#FFBE00',
      grey: '#D8DDE3',
      caramel: '#FFDC95',
      cultured: '#F5F4F1',
    },

    typography: {
      secondary: '#666561',
      tertiary: '#A3A19E',
      disabled: '#B8B7B5',
      link: '#00A3E0',
    },

    background: {
      warmGray: '#52514E',
      disabled: '#DAD9D7',
      page: '#F2F2F0',
      orange: '#F29912',
      success: '#D4FBD7',
    },

    markers: {
      deepOrange: '#FE5000',
      free: '#002244',
      fuchsia: '#E31C79',
      electricBlue: '#0095D2',
      premiumAdditional: '#FCE8E5',
      freeAdditionalPink: '#FCE5F0',
      subscriptionAdditional: '#E0F4FB',
      freeAdditionalBlue: '#EAF1F9',
      privateAdditional: '#F0FADA',
    },

    clickablePrimary: {
      active: '#FFBE00',
      hover: '#FF9900',
      pressed: '#E7800B',
      activeAdditional: '#DCD7C0',
      pressedAdditional: '#BB9A74',
      glowHover: '#FFF4BD',
      glowFocus: '#E1640B',
    },

    clickableSecondary: {
      active: '#8F8D87',
      hover: '#47453F',
      pressed: '#292823',
      activeAdditional: '#CCCAC6',
      pressedAdditional: '#8F8D8B',
      glowHover: '#F2F2F0',
      glowFocus: '#EBEBE9',
      disabled: '#CCCBC9',
    },

    borders: {
      greyBorder: '#E2E1E0',
    },

    support: {
      green: '#33A23B',
      gray: '#52514E',
      lightGray: '#E5E5E3',
      redLight: '#FFDBDB',
      greenLight: '#D3F8E0',
      orange: '#DF5D14',
      orangeLight: '#FFEDB8',
    },
  },
})

export const theme = createTheme(
  themeWithVars as any,
  {
    typography: {
      fontFamily: 'Inter',

      allVariants: { color: themeWithVars.palette.common.black },

      h1: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '56px',
        lineHeight: '64px',
        letterSpacing: '-1.5px',
      },

      h2: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-1px',
      },

      h3: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: '-0.75px',
      },

      h4: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '29px',
        lineHeight: '36px',
        letterSpacing: '-0.5px',
      },

      h5: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '-0.25px',
      },

      h6: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '-0.15px',
      },

      subtitle1: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
      },

      subtitle2: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
      },

      body1: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
      },

      body2: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
      },

      label: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '-0.15px',
      },

      caption: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
      },

      overline: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
      },

      button: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        textAlign: 'center',
      },
    } as ExtendedTypographyOptions,

    mixins: {
      withBorder: {
        border: `1px solid ${themeWithVars.palette.common.grey}`,
      },
      withBorderLeft: {
        borderLeft: `1px solid ${themeWithVars.palette.common.grey}`,
      },
      withTopBorder: {
        borderTop: `1px solid ${themeWithVars.palette.common.grey}`,
      },
      withBottomBorder: {
        borderBottom: `1px solid ${themeWithVars.palette.common.grey}`,
      },
      withBorderRadius: {
        borderRadius: '4px',
      },
      withShadow: {
        boxShadow: '0px -1px 0px rgba(14, 10, 0, 0.12)',
      },
      withStaticModal: {
        borderRadius: 12,
        boxShadow:
          '0px 16px 24px rgba(13, 10, 0, 0.14), 0px 6px 30px rgba(13, 10, 0, 0.12), 0px 8px 10px rgba(13, 10, 0, 0.2)',
      },
    },

    components: {
      MuiTableCell: {
        styleOverrides: { root: { padding: 0 } },
        defaultProps: { align: 'left' },
      },

      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            '*': {
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              fontFamily: 'Inter',
              boxSizing: 'border-box',
              padding: 0,
              margin: 0,
              marginBlockStart: '0px !important',
            },

            div: {
              '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
            },

            '.viewer-wrapper': {
              borderRadius: 8,
            },

            body: {
              backgroundColor: themeWithVars.palette.common.white,
              color: themeWithVars.palette.common.black,
              height: '100%',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',

              '& input::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '& input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            },
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: { variant: 'main' },
            style: {
              backgroundColor: themeWithVars.palette.clickablePrimary.active,
              color: themeWithVars.palette.common.black,
              padding: '12px 0',

              '&:hover': {
                backgroundColor: themeWithVars.palette.clickablePrimary.hover,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '16px',

            textAlign: 'center',
            boxShadow: 'none',
            borderRadius: 8,
            textTransform: 'capitalize',
            color: themeWithVars.palette.typography.secondary,

            '&:hover': {
              boxShadow: 'none',
              transition: 'all 0.1s linear',
            },

            '&.Mui-disabled': {
              backgroundColor: themeWithVars.palette.common.cultured,
              border: 'none',
              color: themeWithVars.palette.typography.disabled,
            },
          },
          contained: {
            color: themeWithVars.palette.common.black,

            '&:hover': {
              backgroundColor: themeWithVars.palette.clickablePrimary.hover,
            },
          },
          outlined: {
            color: themeWithVars.palette.typography.secondary,
            border: `1px solid ${themeWithVars.palette.clickableSecondary.activeAdditional}`,
          },
          containedError: {
            color: themeWithVars.palette.common.white,
            backgroundColor: themeWithVars.palette.common.red,

            '&:hover': {
              transition: 'all 0.1s linear',
              backgroundColor: themeWithVars.palette.common.red,
              boxShadow: `0 0 0 1px ${alpha(themeWithVars.palette.common.red, 0.6)}`,
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          filled: {
            background: 'transparent',
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: 16,
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            height: '48px',
            color: themeWithVars.palette.common.black,
            borderRadius: 8,
            backgroundColor: themeWithVars.palette.common.white,
            transition: 'all 0.1s linear',
            border: `1px solid ${themeWithVars.palette.clickableSecondary.activeAdditional}`,
            '&:before, &:after': {
              display: 'none',
            },
            '&.Mui-focused': {
              border: `1px solid ${themeWithVars.palette.common.golden}`,
              outline: `2px solid ${themeWithVars.palette.common.caramel}`,
              backgroundColor: themeWithVars.palette.common.white,
            },

            '&:hover': {
              backgroundColor: themeWithVars.palette.common.white,
            },

            '&.Mui-error': {
              border: `1px solid ${themeWithVars.palette.common.red}`,
            },

            '&.Mui-error.Mui-focused': {
              outline: `2px solid ${themeWithVars.palette.support.redLight}`,
            },
          },
          input: {
            marginTop: '-5px',
          },
          multiline: {
            height: 'auto',
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              fontSize: '12px',
              lineHeight: '16px',
              color: `${themeWithVars.palette.common.red}`,
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            color: themeWithVars.palette.common.black,
            borderRadius: 8,
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeWithVars.palette.clickableSecondary.activeAdditional,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: themeWithVars.palette.clickableSecondary.activeAdditional,
              borderWidth: 1,
            },
          },
          input: {
            padding: '11.45px 8px',
          },
          notchedOutline: {
            borderColor: themeWithVars.palette.clickableSecondary.activeAdditional,
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            color: themeWithVars.palette.typography.secondary,
            '&.Mui-focused': {
              color: themeWithVars.palette.typography.secondary,
            },

            marginTop: '-3px',
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontSize: 14,
            lineHeight: 16,
            textTransform: 'initial',
            height: '100%',
            color: themeWithVars.palette.typography.secondary,

            '&.Mui-selected': {
              color: themeWithVars.palette.common.black,
            },
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-input': {
              padding: '20px 8px 4px',
            },

            '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${themeWithVars.palette.common.golden}`,
              outline: `2px solid ${themeWithVars.palette.common.caramel}`,
            },

            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
              maxHeight: '48px',
              borderColor: themeWithVars.palette.typography.secondary,
            },

            '& .MuiFormLabel-root.MuiInputLabel-root': {
              top: '-3px',

              '&.Mui-error': {
                color: themeWithVars.palette.typography.secondary,
              },
            },
            legend: {
              display: 'none',
            },
          },
        },
      },

      MuiPickerStaticWrapper: {
        styleOverrides: {
          root: {
            width: '100%',

            '& .MuiPickersFadeTransitionGroup-root': {
              '&:hover.MuiPickersCalendarHeader-labelContainer': {
                ...themeWithVars.mixins.withBorderRadius,
                backgroundColor: themeWithVars.palette.clickableSecondary.secondary,
              },
            },

            '& .MuiCalendarOrClockPicker-root': {
              width: '100%',

              '& > div:first-child ': {
                width: '100%',
              },
            },

            '& .MuiPickerStaticWrapper-content': {
              minWidth: '100%',
            },
          },
        },
      },

      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            width: '100%',

            '& .MuiPickersCalendarHeader-root': {
              color: themeWithVars.palette.typography.secondary,
              margin: 0,
              paddingLeft: 0,
              paddingRight: 0,
            },

            '& .MuiIconButton-root': {
              padding: '3px',
            },

            '& .MuiPickersCalendarHeader-labelContainer': {
              ...themeWithVars.typography.subtitle2,
              padding: '4px',
            },

            '& .MuiPickersDay-root': {
              width: '32px',
              height: '32px',

              '&:hover': {
                backgroundColor: themeWithVars.palette.clickablePrimary.glowHover,
              },

              '&[aria-current="date"]': {
                border: `1px solid ${themeWithVars.palette.common.golden}`,
              },

              '&.Mui-selected': {
                backgroundColor: themeWithVars.palette.common.golden,

                '&:hover': {
                  backgroundColor: themeWithVars.palette.common.golden,
                },

                '&:focus': {
                  backgroundColor: themeWithVars.palette.common.golden,
                },
              },
            },

            '& .PrivatePickersYear-yearButton, & .PrivatePickersMonth-root': {
              '&.Mui-selected': {
                backgroundColor: themeWithVars.palette.common.white,
                border: `1px solid ${themeWithVars.palette.common.golden}`,

                '&:hover': {
                  backgroundColor: themeWithVars.palette.clickablePrimary.glowHover,
                },

                '&:focus': {
                  backgroundColor: themeWithVars.palette.common.white,
                },
              },

              '&:hover': {
                backgroundColor: themeWithVars.palette.clickablePrimary.glowHover,
              },

              '&:focus': {
                backgroundColor: themeWithVars.palette.common.white,
              },
            },

            button: {
              ...themeWithVars.typography.body2,
            },
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            border: '1px solid #E2E1E0',
            width: 'auto',
            height: 'auto',
          },
          square: {
            borderRadius: '8px',
          },
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            pointerEvents: 'initial',
            userSelect: 'initial',
          },
        },
      },
    },
  } as ThemeOptions,
)
