import React, { Suspense } from 'react'
import AppRouter from 'router'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import Snackbar from 'modules/common/components/Snackbar'
import Spinner from './modules/common/components/Spinner'
import { IdleTimerProvider } from 'react-idle-timer'
import { useAppDispatch } from './store'
import { logout, selectAccessToken } from './store/slices/user'
import { useSelector } from 'react-redux'
import { useKeepTokenAlive } from './hooks/useKeepTokenAlive'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      height: '100vh',
      width: '100vw',
    },
  }),
)

function App() {
  const { container } = useStyles()

  useKeepTokenAlive()

  const accessToken = useSelector(selectAccessToken)
  const dispatch = useAppDispatch()

  const onIdle = () => {
    if (accessToken) {
      dispatch(logout())
    }
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * Number(process.env.REACT_APP_INACTIVE_LOGOUT_TIME || 30)}
      debounce={1000 * 2}
      onIdle={onIdle}
    >
      <Box className={container}>
        <Snackbar />

        <Suspense fallback={<Spinner color='black' isBackdrop />}>
          <AppRouter />
        </Suspense>
      </Box>
    </IdleTimerProvider>
  )
}

export default App
