import { PaginationResult } from 'types/common/pagination-result'
import { keysToCamel, keysToSnake } from 'modules/common/helpers/snakeToCamel'
import {
  DeleteAccountRequest,
  GetUsersParams,
  IDeleteReason,
  IEditUserAddressRequest,
  IEditUserProfileRequest,
  SuspendAccountRequest,
  UsersStatistic,
} from './types'
import { baseApi } from '../base'
import { IAdminAccount, IUser, IUserDetails } from 'types/users'
import { ReasonOutType } from 'modules/common/components/Modals/ActionModal'
import { getParams } from '../../../modules/common/helpers/getParams'

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<PaginationResult<IUser>, GetUsersParams>({
      query: (params) => ({ url: `accounts/?${getParams(params)}` }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Users'],
    }),

    getUser: builder.query<IUserDetails, string>({
      query: (user_id) => ({ url: `accounts/${user_id}/retrieve/` }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Users'],
    }),

    getAdminAccount: builder.query<IAdminAccount, void>({
      query: () => ({ url: `accounts/admin-account/retrieve/` }),
      transformResponse: (res) => keysToCamel(res),
      providesTags: ['Users'],
    }),

    getUsersStatistic: builder.query<UsersStatistic, void>({
      query: () => ({ url: `accounts/calculate/` }),
      transformResponse: (res) => keysToCamel(res),
    }),

    getReasons: builder.query<ReasonOutType[], 'delete' | 'suspend' | void>({
      query: (type = 'delete') => ({ url: `accounts/delete-reasons/?delete_type=${type}` }),
      transformResponse: (res) =>
        (keysToCamel(res) as IDeleteReason[]).map(({ number, name }) => ({ id: number, name })),
    }),

    updateSuspendAccount: builder.mutation<void, SuspendAccountRequest>({
      query: ({ id, ...payload }) => ({
        url: `accounts/${id}/suspend-status/update/`,
        method: 'PUT',
        body: keysToSnake(payload),
      }),
      transformResponse: (res) => keysToCamel(res),
      invalidatesTags: ['Users'],
    }),

    deleteAccount: builder.mutation<void, DeleteAccountRequest>({
      query: ({ id, ...payload }) => ({
        url: `accounts/${id}/delete/`,
        method: 'DELETE',
        body: keysToSnake(payload),
      }),
      transformResponse: (res) => keysToCamel(res),
      invalidatesTags: ['Users'],
    }),

    hardDeleteAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `accounts/${id}/hard-delete/`,
        method: 'POST',
      }),
      transformResponse: (res) => keysToCamel(res),
      invalidatesTags: ['Users'],
    }),

    editUserProfile: builder.mutation<void, Partial<IEditUserProfileRequest>>({
      query: ({ userId, payload }) => ({
        url: `accounts/${userId}/profile/edit/`,
        method: 'PATCH',
        body: keysToSnake(payload),
      }),
      transformResponse: (res) => keysToCamel(res),
      invalidatesTags: ['Users'],
    }),
    editUserAddress: builder.mutation<void, Partial<IEditUserAddressRequest>>({
      query: ({ userId, payload }) => ({
        url: `accounts/${userId}/address/edit/`,
        method: 'PATCH',
        body: keysToSnake(payload),
      }),
      transformResponse: (res) => keysToCamel(res),
      invalidatesTags: ['Users'],
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetUsersStatisticQuery,
  useGetAdminAccountQuery,
  useUpdateSuspendAccountMutation,
  useGetReasonsQuery,
  useDeleteAccountMutation,
  useHardDeleteAccountMutation,
  useEditUserProfileMutation,
  useEditUserAddressMutation,
} = usersApi
