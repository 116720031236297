import { lazy } from 'react'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'

import Routes, { DashboardRoutes, WebsiteManagementRoutes } from 'router/routes-list'
import ProtectedRoute from './ProtectedRoute'

const Dashboard = lazy(() => import('modules/dashboard'))

const Evidence = lazy(() => import('modules/evidence'))
const Users = lazy(() => import('modules/users'))
const Payments = lazy(() => import('modules/payments'))
const Analytics = lazy(() => import('modules/analytics'))
const Pricing = lazy(() => import('modules/pricing'))
const PromoCodes = lazy(() => import('modules/promo-codes'))
const AppManagement = lazy(() => import('modules/app-management'))

const SignIn = lazy(() => import('modules/authentication/sign-in'))

// const ManagementPanel = lazy(() => import('modules/management-panel'))
const ManagementHeader = lazy(() => import('modules/management-panel/header'))
const ManagementMain = lazy(() => import('modules/management-panel/main-page'))
const ManagementWelcomePage = lazy(() => import('modules/management-panel/welcome-page'))
const ManagementCSR = lazy(() => import('modules/management-panel/csr'))
const ManagementOurTeam = lazy(() => import('modules/management-panel/team'))
const ManagementJobs = lazy(() => import('modules/management-panel/jobs'))
const ManagementOurPartners = lazy(() => import('modules/management-panel/partners'))
const ManagementFAQ = lazy(() => import('modules/management-panel/faq'))
const ManagementTechSupport = lazy(() => import('modules/management-panel/tech-support'))
const ManagementTermsOfUse = lazy(() => import('modules/management-panel/terms-of-use'))
const ManagementPrivacyPolicy = lazy(() => import('modules/management-panel/privacy-policy'))
const ManagementFooter = lazy(() => import('modules/management-panel/footer'))
const Blog = lazy(() => import('modules/management-panel/blog'))
const CreateArticle = lazy(() => import('modules/management-panel/blog/tab-content/articles/create-article'))
const EditArticle = lazy(() => import('modules/management-panel/blog/tab-content/articles/edit-article'))

const AppRouter = () => {
  return (
    <Switch>
      <Route path={Routes.SIGN_IN} element={<SignIn />} />

      <Route
        path={Routes.DASHBOARD}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to={`${Routes.DASHBOARD}/${WebsiteManagementRoutes.HEADER}`} replace />} />

        <Route path={DashboardRoutes.EVIDENCE} element={<Evidence />} />
        <Route path={DashboardRoutes.USERS} element={<Users />} />
        {/*<Route path={DashboardRoutes.ORGANIZATIONS} element={<></>} />*/}
        {/*<Route path={DashboardRoutes.ACCESSIBILITY} element={<></>} />*/}
        <Route path={DashboardRoutes.PAYMENTS} element={<Payments />} />
        <Route path={DashboardRoutes.ANALYTICS} element={<Analytics />} />
        <Route path={DashboardRoutes.PRICING} element={<Pricing />} />
        <Route path={DashboardRoutes.PROMO_CODES} element={<PromoCodes />} />
        <Route path={DashboardRoutes.APP_MANAGEMENT} element={<AppManagement />} />
        <Route path={DashboardRoutes.EDIT_ARTICLE}>
          <Route path={':articleId'} element={<EditArticle />} />{' '}
        </Route>
        <Route path={DashboardRoutes.CREATE_ARTICLE} element={<CreateArticle />} />
        {/*<Route path={DashboardRoutes.ACTIVITIES} element={<></>} />*/}
        {/*<Route path={DashboardRoutes.GUIDED_RECORDING_MANAGEMENT} element={<></>} />*/}
        {/*<Route path={DashboardRoutes.APP_MANAGEMENT} element={<></>} />*/}

        {/*<Route path={DashboardRoutes.MANAGEMENT_PANEL} element={<ManagementPanel />} />*/}
        <Route path={WebsiteManagementRoutes.HEADER} element={<ManagementHeader />} />
        <Route path={WebsiteManagementRoutes.MAIN} element={<ManagementMain />} />
        <Route path={WebsiteManagementRoutes.WELCOME} element={<ManagementWelcomePage />} />
        <Route path={WebsiteManagementRoutes.OUR_TEAM} element={<ManagementOurTeam />} />
        <Route path={WebsiteManagementRoutes.OUR_PARTNERS} element={<ManagementOurPartners />} />
        <Route path={WebsiteManagementRoutes.JOBS} element={<ManagementJobs />} />
        <Route path={WebsiteManagementRoutes.CSR} element={<ManagementCSR />} />
        <Route path={WebsiteManagementRoutes.FAQ} element={<ManagementFAQ />} />
        <Route path={WebsiteManagementRoutes.TECH_SUPPORT} element={<ManagementTechSupport />} />
        <Route path={WebsiteManagementRoutes.TERMS_OF_USE} element={<ManagementTermsOfUse />} />
        <Route path={WebsiteManagementRoutes.PRIVACY_POLICY} element={<ManagementPrivacyPolicy />} />
        <Route path={WebsiteManagementRoutes.FOOTER} element={<ManagementFooter />} />
        <Route path={WebsiteManagementRoutes.BLOG} element={<Blog />} />

        <Route path='*' element={<Navigate to={`${Routes.DASHBOARD}/${WebsiteManagementRoutes.HEADER}`} replace />} />
      </Route>

      <Route path='*' element={<Navigate to={Routes.SIGN_IN} replace />} />
    </Switch>
  )
}

export default AppRouter
