import { Navigate } from 'react-router-dom'
import Routes from './routes-list'
import { useSelector } from 'react-redux'
import { selectAccessToken } from '../store/slices/user'
import { FC, ReactNode } from 'react'

const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const accessToken = useSelector(selectAccessToken)

  if (!accessToken) {
    return <Navigate to={Routes.SIGN_IN} replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
